<template>
  <div>
    <div id="wrapper">
      <!-- Sidebar -->
      <ul
        class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <!-- Sidebar - Brand -->
        <router-link to="/">
          <a
            class="
              sidebar-brand
              d-flex
              align-items-center
              justify-content-center
            "
            style="background-color: #95abec"
          >
            <div class="d-flex justify-content-center w-100">
              <img class="fas" src="../../../assets/img/cropped-logo_lys.png">
            </div>
           
            <div class="d-flex justify-content-center w-100">
              <i
                class="mb-2"
                src="../../../assets/img/cropped-logo_lys.png"
                alt=""
                width="80%"
              ></i>
            </div>
          </a>
        </router-link>

        <!-- Divider -->
        <hr class="sidebar-divider my-0" />

        <!-- Heading -->

        <!-- Divider -->
        <hr class="sidebar-divider" />

        <!-- Heading -->
        <div class="sidebar-heading fs-5 mb-5">Ambientes</div>

        <div v-if="this.is_admin==true"> 
          <!-- Rotas para Comercial -->
          <li class="nav-item active collapsed">
            <a
              class="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapseComercial"
              aria-expanded="true"
              aria-controls="collapsePages"
              id="liCollapseComercial"
            >
              <i class="fas fa-fw fa-folder"></i>
              <span>Comercial</span>
            </a>
            <div
              id="collapseComercial"
              class="collapse submenu-full"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div class="bg-white py-2 collapse-inner submenu-collapse">
                <h6 class="collapse-header">Opções</h6>

                <div v-for="route in routes" v-bind:key="route">
                  <div class="collapse-item" v-if="route.categoria == 'comercial'">
                    <router-link v-if="route.categoria == 'comercial'" :to="route.path ? route.path : '/'" class="d-flex w-100">
                      {{ route.titulo }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>

        <div v-if="this.is_admin==true"> 
          <!-- Rotas para Metodologia -->
          <li class="nav-item active collapsed">
            <a
              class="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapseMetodologia"
              aria-expanded="true"
              aria-controls="collapsePages"
              id="liCollapseMetodologia"
            >
              <i class="fas fa-fw fa-folder"></i>
              <span>Metodologia</span>
            </a>
            <div
              id="collapseMetodologia"
              class="collapse submenu-full"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div class="bg-white py-2 collapse-inner  submenu-collapse">
                <h6 class="collapse-header">Opções</h6>

                <div v-for="route in routes" v-bind:key="route">
                  <div class="collapse-item" v-if="route.categoria == 'metodologia'">
                    <router-link v-if="route.categoria == 'metodologia'" :to="route.path ? route.path : '/'" class="d-flex w-100">
                      {{ route.titulo }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
        
        <!-- Rotas para Interação -->
        <li class="nav-item active collapsed">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapsePages"
            aria-expanded="true"
            aria-controls="collapsePages"
            id="liCollapsePages"
          >
            <i class="fas fa-fw fa-folder"></i>
            <span>Interação</span>
          </a>
          <div
            id="collapsePages"
            class="collapse submenu-full"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div class="bg-white py-2 collapse-inner  submenu-collapse">
              <h6 class="collapse-header">Opções</h6>

              <div v-for="route in routes" v-bind:key="route">
                <div class="collapse-item" v-if="route.categoria == 'interacao'">
                  <router-link v-if="route.categoria == 'interacao'" :to="route.path ? route.path : '/'" class="d-flex w-100">
                    {{ route.titulo }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
       
        <!-- Rotas para Fabrica -->
        <!--<li class="nav-item active collapsed">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseFabrica"
            aria-expanded="true"
            aria-controls="collapseFabrica"
          >
            <i class="fas fa-fw fa-folder"></i>
            <span>Fabrica</span>
          </a>
          <div
            id="collapseFabrica"
            class="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div class="bg-white py-2 collapse-inner rounded">
              <h6 class="collapse-header">Opções</h6>
              <div class="collapse-item">
                <div v-for="route in routes" v-bind:key="route">
                  <router-link
                    v-if="route.categoria == 'fabrica'"
                    :to="route.path ? route.path : '/'"
                    ><a>{{ route.titulo }}</a></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </li>-->
      </ul>
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
          <!-- Topbar -->
          <nav
            class="
              navbar navbar-expand navbar-light
              bg-white
              topbar
              mb-4
              static-top
              shadow
            "
          >
            <!-- Sidebar Toggle (Topbar) -->
            <button
              id="sidebarToggleTop"
              class="btn btn-link d-md-none rounded-circle mr-3"
            >
              <i class="fa fa-bars"></i>
            </button>

            <!-- Topbar Search -->
            <form
              class="
                d-none d-sm-inline-block
                form-inline
                mr-auto
                ml-md-3
                my-2 my-md-0
                mw-100
                navbar-search
              "
            ></form>

            <!-- Topbar Navbar -->
            <ul class="navbar-nav ml-auto">
              <!-- Nav Item - Search Dropdown (Visible Only XS) -->
              <li class="nav-item dropdown no-arrow d-sm-none">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="searchDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-search fa-fw"></i>
                </a>
                <!-- Dropdown - Messages -->
                <div
                  class="
                    dropdown-menu dropdown-menu-right
                    p-3
                    shadow
                    animated--grow-in
                  "
                  aria-labelledby="searchDropdown"
                >
                  <form class="form-inline mr-auto w-100 navbar-search">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control bg-light border-0 small"
                        placeholder="Search for..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button">
                          <i class="fas fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>

              <!-- Nav Item - Alerts -->
              <li class="nav-item dropdown no-arrow mx-1">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="alertsDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-bell fa-fw"></i>
                  <!-- Counter - Alerts -->
                  <span class="badge badge-danger badge-counter">3+</span>
                </a>
                <!-- Dropdown - Alerts -->
                <div
                  class="
                    dropdown-list dropdown-menu dropdown-menu-right
                    shadow
                    animated--grow-in
                  "
                  aria-labelledby="alertsDropdown"
                >
                  <h6 class="dropdown-header">Alerts Center</h6>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3">
                      <div class="icon-circle bg-primary">
                        <i class="fas fa-file-alt text-white"></i>
                      </div>
                    </div>
                    <div>
                      <div class="small text-gray-500">December 12, 2019</div>
                      <span class="font-weight-bold"
                        >A new monthly report is ready to download!</span
                      >
                    </div>
                  </a>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3">
                      <div class="icon-circle bg-success">
                        <i class="fas fa-donate text-white"></i>
                      </div>
                    </div>
                    <div>
                      <div class="small text-gray-500">December 7, 2019</div>
                      $290.29 has been deposited into your account!
                    </div>
                  </a>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3">
                      <div class="icon-circle bg-warning">
                        <i class="fas fa-exclamation-triangle text-white"></i>
                      </div>
                    </div>
                    <div>
                      <div class="small text-gray-500">December 2, 2019</div>
                      Spending Alert: We've noticed unusually high spending for
                      your account.
                    </div>
                  </a>
                  <a
                    class="dropdown-item text-center small text-gray-500"
                    href="#"
                    >Show All Alerts</a
                  >
                </div>
              </li>

              <!-- Nav Item - Messages -->
              <li class="nav-item dropdown no-arrow mx-1">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="messagesDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-envelope fa-fw"></i>
                  <!-- Counter - Messages -->
                  <span class="badge badge-danger badge-counter">7</span>
                </a>
                <!-- Dropdown - Messages -->
                <div
                  class="
                    dropdown-list dropdown-menu dropdown-menu-right
                    shadow
                    animated--grow-in
                  "
                  aria-labelledby="messagesDropdown"
                >
                  <h6 class="dropdown-header">Message Center</h6>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                      <img class="rounded-circle" src="" alt="..." />
                      <div class="status-indicator bg-success"></div>
                    </div>
                    <div class="font-weight-bold">
                      <div class="text-truncate">
                        Hi there! I am wondering if you can help me with a
                        problem I've been having.
                      </div>
                      <div class="small text-gray-500">Emily Fowler · 58m</div>
                    </div>
                  </a>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                      <img
                        class="rounded-circle"
                        src="../../../assets/img/undraw_profile_2.svg"
                        alt="..."
                      />
                      <div class="status-indicator"></div>
                    </div>
                    <div>
                      <div class="text-truncate">
                        I have the photos that you ordered last month, how would
                        you like them sent to you?
                      </div>
                      <div class="small text-gray-500">Jae Chun · 1d</div>
                    </div>
                  </a>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                      <img
                        class="rounded-circle"
                        src="img/undraw_profile_3.svg"
                        alt="..."
                      />
                      <div class="status-indicator bg-warning"></div>
                    </div>
                    <div>
                      <div class="text-truncate">
                        Last month's report looks great, I am very happy with
                        the progress so far, keep up the good work!
                      </div>
                      <div class="small text-gray-500">Morgan Alvarez · 2d</div>
                    </div>
                  </a>
                  <!-- <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                      <img
                        class="rounded-circle"
                        src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                        alt="..."
                      />
                      <div class="status-indicator bg-success"></div>
                    </div>
                    <div>
                      <div class="text-truncate">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good...
                      </div>
                      <div class="small text-gray-500">
                        Chicken the Dog · 2w
                      </div>
                    </div>
                  </a> -->
                  <a
                    class="dropdown-item text-center small text-gray-500"
                    href="#"
                    >Read More Messages</a
                  >
                </div>
              </li>

              <div class="topbar-divider d-none d-sm-block"></div>

              <!-- Nav Item - User Information -->
              <li class="nav-item dropdown no-arrow">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{
                    name_user
                  }}</span>
                  <img
                    class="img-profile rounded-circle"
                    src="../../../assets/img/undraw_profile.svg"
                  />
                </a>
                <!-- Dropdown - User Information -->
                <div
                  class="
                    dropdown-menu dropdown-menu-right
                    shadow
                    animated--grow-in
                  "
                  aria-labelledby="userDropdown"
                >
                  <a class="dropdown-item">
                    <i class="bi bi-person-check" style="font-size: 1.3rem; color: #198754;"></i>
                     {{ email_user }}
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="bi bi-eye" style="font-size: 1.1rem; "></i>
                    <!--<i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>-->
                    Visualizar
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="bi bi-gear" style="font-size: 1.1rem; "></i>
                    Configurações
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="bi bi-file-text" style="font-size: 1.1rem; "></i>
                    Log de Atividades
                  </a>
                  <div class="dropdown-divider"></div>

                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#logoutModal"
                    v-on:click="Logout()"
                    style="font-size: 1rem;"
                  >
                  <i class="bi bi-power" style="font-size: 1rem; color: red;"></i>
                    Sair
                  </a>
                </div>
              </li>
            </ul>
          </nav>
          <!-- End of Topbar -->

          <div class="container-fluid">
            <div class="row">
              <transition name="pagina">
                <router-view></router-view>
              </transition>
            </div>
          </div>
          <!--
        Conteúdo da página
        --></div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <footer class="sticky-footer bg-white">
          <div class="container2 my-auto2">
            <div class="copyright text-center my-auto">
              <span>&copy; Lys Academy </span>
            </div>
          </div>
        </footer>
        <!-- End of Footer -->
      </div>
      <!-- End of Content Wrapper -->
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import {
    http
} from "../../../http";
export default {
  props: ["name_user", "email_user", "is_tutor", "is_staff", "is_admin"],

  data() {
    return {
      routes: [
        //Rotas Interação
        {
          path: "/chat", categoria: "interacao", titulo: "Chat Tutor" ,
        },
        {
          path: "/curated-list", categoria: "interacao", titulo: "Curadoria de Conteúdo" ,
        },

        // Rotas Painel Interação
        { path: "/enrolments-interacao", categoria: "interno-interacao",  titulo: "Listagem de Inscritos" },
        
        // Rotas Comercial
        { path: "/client", categoria: "comercial",  titulo: "Carteira de Clientes" },
        { path: "/enrollments-information", categoria: "comercial",  titulo: "Informações dos Alunos" },
        { path: "/push-batches-information", categoria: "comercial",  titulo: "Push Batches" },
        { path: "/messages-information", categoria: "comercial",  titulo: "Informações das Mensagens" },
  
        //Rotas Metodologia
        { path: "/metodologia", categoria: "metodologia", titulo: "Controles" },
        { path: "/sender-control", categoria: "metodologia", titulo: "Senders" },
      ],
      email: "",
      password: "",
      token: "",
      title: "Login Lys",
      name_user:"",
      ueser_id:"",
      email_user:',',
      is_admin:"",
      is_staff:"",
      is_tutor:"",
    };    
  },

  mounted() {
    this.getInforUser();
  },

  methods: {
    Logout: function(){
      useCookies().cookies.remove("user_session");
      useCookies().cookies.remove("set_user_info");

      // this.$cookies.remove("user_session");
      // this.$cookies.remove("set_user_info");
      window.location.reload();
    },

    getInforUser: function () {
      var token = null;

      if (useCookies().cookies.isKey("user_session")) {
        // token = this.$cookies.get("user_session");
        token = useCookies().cookies.get("user_session");
      }

      if (token !== null && token!=='' && token!==undefined) {
        const payload = {
            init_user: token,
        };

        http.post("info_user_for_token", payload)
        .then((response) => {
          this.name_user = response.data["first_name"] + " " + response.data["last_name"];
          this.ueser_id = response.data["user_id"];
          this.email_user = response.data["email"];
          this.is_admin = response.data["is_admin"];
          this.is_staff = response.data["is_staff"];
          this.is_tutor = response.data["is_tutor"];
        })
        .catch(function (error) {})
        // console.log(this.is_admin);
      }
    },
  }, 
};
</script>

<style>
a {
  text-decoration: none !important;
}
</style>
