<template>
    <div
      class="modal fade"
      id="modalDatailsSender"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalDatailsSenderLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDatailsSenderLabel">Alocação do Sender</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card" style="width: 18rem;">
              <div class="card-header">Cursos</div>
              <ul class="list-group list-group-flush">
                <li
                  v-for="(item, id) in list_courses"
                  :key="id"
                  class="list-group-item"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
  
        <div class="modal-header">
            <h5 class="modal-title" id="modalDatailsSenderLabel">
                Histórico de Alocação do Sender
            </h5>
            </div>
            <div class="modal-body">
                <div class="card-header">Cursos</div>
                <ul class="list-group list-group-flush">
                    <li v-if="list_histroy && list_histroy.length > 0"
                        v-for="(item, id) in list_histroy"
                        :key="id"
                        class="list-group-item"
                    >
                        <div><strong>Curso:</strong> {{ item.course__name }}</div>
                        <div><strong>Cliente:</strong> {{ item.course__company__client__name }}</div>
                        <div><strong>Ativo:</strong> {{ item.course__is_active ? 'Sim' : 'Não' }}</div>
                    </li>
                    <li v-else class="list-group-item">
                        <div>Nenhum curso encontrado.</div>
                    </li>
                </ul>
            </div>

        </div>
      </div>
    </div>
</template>
  
<script>
  import { http } from "../../http";
  
    export default {
    name: "ModalSenders",
    props: {
      fields: {
        type: Number,
        required: true, 
      },
    },
    data() {
      return {
        loading: true, 
        list_courses: [],
        list_histroy:[],
      };
    },
    watch: {
      fields: {
        immediate: true,
        handler(newSenderId) {
          if (newSenderId) {
            this.sender_allocated(newSenderId);
            this.sender_history(newSenderId);
          }
        },
      },
    },
    methods: {
      sender_allocated(sender_id) {
        this.loading = true; 
        this.list_courses = []; 
  
        const payload = { sender_id };
  
        http
          .post("painel/atend_comercial/sender_allocated/", payload)
          .then((res) => {
            if (res.data) {
              this.list_courses = res.data;
            } else {
              console.error("Estrutura de resposta inesperada:", res);
            }
          })
          .catch((error) => {
            console.error("Erro ao buscar detalhes do sender:", error);
          })
          .finally(() => {
            this.loading = false; 
          });
      },
      sender_history(sender_id){
        this.loading = true; 
        this.list_courses = []; 
  
        const payload = { sender_id };
  
        http
          .post("painel/atend_comercial/sender_history/", payload)
          .then((res) => {
            if (res.data) {
              this.list_histroy = res.data;
            } else {
              console.error("Estrutura de resposta inesperada:", res);
            }
          })
          .catch((error) => {
            console.error("Erro ao buscar detalhes do sender:", error);
          })
          .finally(() => {
            this.loading = false; 
          });

      },
    },
  };
</script>
  
<style>

  .btn-pagination {
    margin: 0;
    border: none;
    background-color: transparent;
    color: black;
    font-weight: bold;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
</style>
  